<template>
  <div class="complete"></div>
</template>

<script>
import { TaskProvider } from "@/providers";

export default {
  name: "SimpleTaskJournal",
  props: {
    task: Object
  },

  data() {
    return {
      taskStatus: ""
    };
  },

  created() {
    if (this.task.status === "completed") {
      this.journalDone(this.task);
      this.taskStatus = this.task.status;
    }
  },

  mounted() {
    this.doJournal("");
  },

  methods: {
    async doJournal(response) {
      TaskProvider.doTaskJournal(this.task.id, response).then(response =>
        this.journalDone(response.data)
      );
    },

    journalDone(task) {
      window.Event.trigger("TaskJournalingCompleted", task);
    },

    cancelJournal() {
      this.$router.push({
        name: "home-page"
      });
    }
  }
};
</script>

<style scoped>
.complete {
  margin-top: 50px;
}
</style>
